import { Message, SourceNode } from '../types/message';
import { getAIAssistantResponse } from '../api/patiaAI';
import { SetStateAction } from 'react';
import { RecordedAction } from '../pages/teach/recordedAction';

export const convertToUserMessage = (
  id: number,
  text: string,
  username: string
): Message => {
  return {
    id: id,
    text: text,
    sender: username,
    isLoading: false,
    isDraft: false,
    sourceNodes: [],
    instructions: []
  };
};

export const convertToAIMessage = (
  id: number,
  agentName: string,
  text: string,
  isLoading: boolean = false,
  isDraft: boolean = false,
  sourceNodes: SourceNode[] = [],
  instructions: RecordedAction[] = []
): Message => {
  return {
    id: id,
    text: text,
    sender: agentName,
    isLoading: isLoading,
    isDraft: isDraft,
    sourceNodes: sourceNodes,
    instructions: instructions
  };
};

export const sendAIMessage = (
  message: string,
  agentName: string,
  setMessages: {
    (value: SetStateAction<Message[]>): void;
    (value: SetStateAction<Message[]>): void;
    (arg0: any[]): void;
  }
) => {
  setMessages((currMessages: Message[]) => {
    const patiaMessage = convertToAIMessage(
      currMessages.length + 1,
      agentName,
      message,
      false
    );

    // convert all draft message to non-draft
    const updatedMessages = currMessages.map((msg: Message) => {
      if (msg.isDraft) {
        msg.isDraft = false;
      }
      return msg;
    });

    return [...updatedMessages, patiaMessage];
  });
};

export const sendMessage = (
  agentName: string,
  message: string,
  setMessages: {
    (value: SetStateAction<Message[]>): void;
    (value: SetStateAction<Message[]>): void;
    (arg0: any[]): void;
  },
  additionalData: object = {}
) => {
  setMessages((currMessages: Message[]) => {
    const newMessage = convertToUserMessage(
      currMessages.length + 1,
      message,
      'User'
    );
    const patiaMessage = convertToAIMessage(
      currMessages.length + 2,
      agentName,
      '',
      true
    );

    // convert all draft message to non-draft
    const updatedMessages = currMessages.map((msg: Message) => {
      if (msg.isDraft) {
        msg.isDraft = false;
      }
      return msg;
    });

    getAIAssistantResponse(
      [...updatedMessages, newMessage],
      additionalData,
      agentName
    )
      .then((responseObject) => {
        // update the message with the response from Patia AI
        patiaMessage.text = responseObject.text;
        patiaMessage.sourceNodes = responseObject.sourceNodes;
        patiaMessage.instructions = responseObject.instructions;
      })
      .catch((error) => {
        console.error('Error sending chat request:', error);
        patiaMessage.text = `Sorry, I'm having trouble connecting to ${agentName}. Please try again later.`;
      })
      .finally(() => {
        patiaMessage.isLoading = false;
        setMessages([...updatedMessages, newMessage, patiaMessage]);
      });

    return [...updatedMessages, newMessage, patiaMessage];
  });
};

export const generateAIResponse = (
  agentName: string,
  setMessages: {
    (value: SetStateAction<Message[]>): void;
    (value: SetStateAction<Message[]>): void;
    (arg0: any[]): void;
  },
  additionalData: object = {}
) => {
  setMessages((currMessages: Message[]) => {
    const patiaMessage = convertToAIMessage(
      currMessages.length + 1,
      agentName,
      '',
      true
    );

    // convert all draft message to non-draft
    const updatedMessages = currMessages.map((msg: Message) => {
      if (msg.isDraft) {
        msg.isDraft = false;
      }
      return msg;
    });

    getAIAssistantResponse(updatedMessages, additionalData, agentName)
      .then((responseObject) => {
        // update the message with the response from Patia AI
        patiaMessage.text = responseObject.text;
        patiaMessage.sourceNodes = responseObject.sourceNodes;
        patiaMessage.instructions = responseObject.instructions;
      })
      .catch((error) => {
        console.error('Error sending chat request:', error);
        patiaMessage.text = `Sorry, I'm having trouble connecting to ${agentName}. Please try again later.`;
      })
      .finally(() => {
        patiaMessage.isLoading = false;
        setMessages([...updatedMessages, patiaMessage]);
      });

    return [...updatedMessages, patiaMessage];
  });
};
