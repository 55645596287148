'use client';
import {
  createContext,
  useContext,
  useReducer,
  useMemo,
  Dispatch,
  ReactNode
} from 'react';
import { Message, EnumReviewType } from '../types/message';
import { SELECTED_LANGUAGE } from '../constants/language';
import { DEFAULT_AGENT_NAME } from '../api/constants';

type MessageState = {
  messages: Message[];
  lastUserResponse: EnumReviewType | undefined;
};

type Action =
  | { type: 'SET_INITIAL_MESSAGE_AGENT'; value: string }
  | { type: 'SET_LAST_USER_RESPONSE'; value: EnumReviewType | undefined }
  | { type: 'SET_MESSAGE'; value: Message[] | Function }
  | { type: 'ADD_MESSAGE'; value: Message }
  | { type: 'REMOVE_LAST' }
  | { type: 'RESET' };

const MessageContext = createContext<
  [MessageState, Dispatch<Action>] | undefined
>(undefined);
const welcomeMessage =
  SELECTED_LANGUAGE === 'en'
    ? `Hi, how can I help you?`
    : `Halo, ada yang bisa saya bantu?`;
const initialState: MessageState = {
  messages: [
    {
      id: 1,
      text: welcomeMessage,
      sender: DEFAULT_AGENT_NAME,
      isLoading: false,
      isDraft: false
    }
  ],
  lastUserResponse: undefined
};

function reducer(state: MessageState, action: Action) {
  switch (action.type) {
    case 'SET_LAST_USER_RESPONSE': {
      return { ...state, lastUserResponse: action.value };
    }
    // TODO: Incase of initial state changes, need to refactor this reducer
    // To set initial state message sender to AI agent (this is done as agentName is asynchronously fetched)
    case 'SET_INITIAL_MESSAGE_AGENT': {
      if (state.messages.length > 0) {
        const updatedMessage = {
          ...state.messages[0], // Keep other fields unchanged
          sender: action.value // Update sender to action.agentName
        };

        // Update the first message in messages array
        const updatedMessages = [
          updatedMessage,
          ...state.messages.slice(1) // Keep other messages unchanged
        ];

        return { ...state, messages: updatedMessages };
      }

      return state; // Return current state if messages array is empty
    }
    case 'SET_MESSAGE': {
      if (typeof action.value === 'function') {
        return { ...state, messages: action.value(state.messages) };
      }

      return { ...state, messages: action.value };
    }
    case 'ADD_MESSAGE': {
      return { ...state, messages: [...state.messages, action.value] };
    }
    case 'REMOVE_LAST': {
      return {
        ...state,
        messages: state.messages.slice(0, state.messages.length - 1)
      };
    }
    case 'RESET': {
      return initialState;
    }
    default: {
      // @ts-ignore
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function MessageContextProvider({ children }: { children: ReactNode }) {
  const reducerValue = useReducer(reducer, initialState);
  const value = useMemo(() => reducerValue, [reducerValue]);

  return (
    <MessageContext.Provider value={value}>{children}</MessageContext.Provider>
  );
}

function useMessageContextController() {
  const context = useContext(MessageContext);

  if (!context) {
    throw new Error(
      'useEmployerContext should be used inside the MessageContextProvider.'
    );
  }
  return context;
}
const setInitialMessageAgent = (dispatch: Dispatch<Action>, value: string) =>
  dispatch({ type: 'SET_INITIAL_MESSAGE_AGENT', value });
const setLastUserResponse = (
  dispatch: Dispatch<Action>,
  value: EnumReviewType | undefined
) => dispatch({ type: 'SET_LAST_USER_RESPONSE', value });
const setMessages = (dispatch: Dispatch<Action>, value: Message[] | Function) =>
  dispatch({ type: 'SET_MESSAGE', value });
const addMessage = (dispatch: Dispatch<Action>, value: Message) =>
  dispatch({ type: 'ADD_MESSAGE', value });
const removeLastMessage = (dispatch: Dispatch<Action>) =>
  dispatch({ type: 'REMOVE_LAST' });
const resetContext = (dispatch: Dispatch<Action>) =>
  dispatch({ type: 'RESET' });

export {
  setInitialMessageAgent,
  setLastUserResponse,
  MessageContextProvider,
  useMessageContextController,
  setMessages,
  addMessage,
  removeLastMessage,
  resetContext
};
