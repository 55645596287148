import React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ProfileIcon from '@mui/icons-material/Person';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { isChromeExtApp } from '../../utils/chromeHelper';
import LogoutButton from '../logout/LogoutButton';
import { Typography } from '@mui/material';
import { WEB_BASE_URL } from '../../api/constants';

const ProfileNavigations = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        boxSizing: 'borderBox'
      }}
    >
      <IconButton
        onClick={handleClick}
        sx={{
          width: '40px',
          height: '40px',
          alignContent: 'center',
          padding: '0'
        }}
        aria-controls={open ? 'account-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        <ProfileIcon
          sx={{
            alignSelf: 'center',
            height: '35px',
            width: '35px',
            margin: 'auto',
            ':hover': {
              cursor: 'pointer'
            }
          }}
        />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        sx={{
          '& .MuiList-root': {
            paddingTop: '2px !important',
            paddingBottom: '2px !important',
            minWidth: '200px'
          }
        }}
      >
        {isChromeExtApp() && (
          <MenuItem
            key="profile"
            sx={{ padding: '0px' }}
            onClick={() => {
              window.open(WEB_BASE_URL, '_blank');
              handleCloseMenu();
            }}
          >
            <Typography
              sx={{
                width: '100%',
                padding: '10px 20px',
                fontSize: '14px'
              }}
            >
              Open Web Application
            </Typography>
          </MenuItem>
        )}
        <MenuItem key="logout" sx={{ padding: '0px' }}>
          <LogoutButton />
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default ProfileNavigations;
