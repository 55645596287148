// NavigationButton.tsx
import React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { SvgIconComponent } from '@mui/icons-material';
import { styled } from '@mui/system';

interface NavigationButtonProps extends NavLinkProps {
  icon: SvgIconComponent;
  text: string;
  disabled?: boolean;
}

const StyledNavLink = styled(NavLink)<{ disabled?: boolean }>(
  ({ disabled }) => ({
    width: '100%',
    minWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0',
    color: 'black',
    fontSize: '10px',
    lineHeight: '12px',
    marginBottom: '10px',
    boxSizing: 'border-box',
    borderRadius: '5px',
    textTransform: 'capitalize',
    textDecoration: 'none',
    pointerEvents: disabled ? 'none' : 'auto',
    opacity: disabled ? 0.5 : 1,
    ':hover': {
      color: '#49BAFD'
    },
    '&:hover .icon-wrapper': {
      backgroundColor: '#FFFFFF' // Hover background color for the icon wrapper
    },
    '&.active': {
      color: '#49BAFD'
    }
  })
);

const IconWrapper = styled('div')({
  width: '24px', // Fixed width for the icon wrapper
  height: '24px', // Fixed height to make it square
  padding: '8px',
  borderRadius: '4px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: '2px', // Minimal margin to place text right below the icon
  '&.active': {
    color: '#49BAFD',
    backgroundColor: '#FFFFFF' // Active background color
  }
});

const NavigationButton: React.FC<NavigationButtonProps> = ({
  icon: Icon,
  text,
  to,
  disabled,
  ...props
}) => {
  return (
    <StyledNavLink to={to} disabled={disabled} {...props}>
      {({ isActive }) => (
        <>
          <IconWrapper className={`icon-wrapper ${isActive ? 'active' : ''}`}>
            <Icon style={{ fontSize: '24px' }} />
          </IconWrapper>
          {text}
        </>
      )}
    </StyledNavLink>
  );
};

export default NavigationButton;
