import React from 'react';
import { Fab } from '@mui/material';
import SmartToyIcon from '@mui/icons-material/SmartToy';

interface StartAutomationModeFabProps {
  onClick: () => void;
}

const StartAutomationModeFab: React.FC<StartAutomationModeFabProps> = ({ onClick }) => {
  return (
    <Fab
      sx={{
        backgroundColor: '#31B1FD',
        color: 'white',
        position: 'absolute', 
        bottom: 16,
        right: 16,
        '&:hover': {
          backgroundColor: '#1E90FF'
        }
      }}
      onClick={onClick}
    >
      <SmartToyIcon />
    </Fab>
  );
};

export default StartAutomationModeFab;
