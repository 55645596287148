export const zoomScreenshot = (
  screenshotUrl: string,
  targetBoundingRect: any,
  callback: (url: string) => void
): void => {
  // Create a canvas to draw the screenshot and the rectangle
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  // Ensure context is not null
  if (!context) {
    console.error('Failed to get canvas context');
    callback(screenshotUrl);
    return;
  }

  // Check if targetBoundingRect has all required properties
  if (
    !targetBoundingRect?.left ||
    !targetBoundingRect?.top ||
    !targetBoundingRect?.width ||
    !targetBoundingRect?.height
  ) {
    callback(screenshotUrl);
    return;
  }

  // Create and load the image
  const img = new Image();
  img.src = screenshotUrl;

  img.onload = () => {
    canvas.width = img.width;
    canvas.height = img.height;

    // Draw the screenshot on the canvas
    context.drawImage(img, 0, 0);

    // Add padding around the target element
    const padding = 100;
    const zoomFactor = 1.5;

    // Calculate source and destination rectangles for zooming
    const sourceX = Math.max(0, targetBoundingRect.left - padding);
    const sourceY = Math.max(0, targetBoundingRect.top - padding);
    const sourceWidth = Math.min(
      img.width - sourceX,
      targetBoundingRect.width + 2 * padding
    );
    const sourceHeight = Math.min(
      img.height - sourceY,
      targetBoundingRect.height + 2 * padding
    );

    // Create a new canvas for the zoomed portion
    const zoomedCanvas = document.createElement('canvas');
    zoomedCanvas.width = sourceWidth * zoomFactor;
    zoomedCanvas.height = sourceHeight * zoomFactor;
    const zoomedContext = zoomedCanvas.getContext('2d');

    if (!zoomedContext) {
      console.error('Failed to get zoomed canvas context');
      callback(screenshotUrl);
      return;
    }

    // Draw the zoomed portion
    zoomedContext.drawImage(
      img,
      sourceX,
      sourceY,
      sourceWidth,
      sourceHeight,
      0,
      0,
      zoomedCanvas.width,
      zoomedCanvas.height
    );

    callback(zoomedCanvas.toDataURL());
  };
};
