import { Box, Button } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const Pagination = ({
  currentPage,
  totalPages,
  onPageChange
}: PaginationProps) => {
  const getPageNumbers = () => {
    const pageNumbers = [];
    const startPage = Math.max(currentPage - 2, 1);
    const endPage = Math.min(currentPage + 2, totalPages);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };

  const handlePageClick = (page: number) => {
    if (page !== currentPage && typeof page === 'number') {
      onPageChange(page);
    }
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  const pageNumbers = getPageNumbers();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '16px',
        width: '100%',
        margin: 'auto'
      }}
    >
      <Button
        disabled={currentPage === 1}
        onClick={handlePrevious}
        size="small"
        sx={{
          minWidth: '32px',
          color: '#49BAFD'
        }}
      >
        <ChevronLeftIcon fontSize="small" />
      </Button>

      {pageNumbers.map((page, index) => (
        <Button
          key={index}
          disabled={page === currentPage || typeof page !== 'number'}
          onClick={() => handlePageClick(page as number)}
          size="small"
          sx={{
            minWidth: '32px',
            padding: '0 8px',
            margin: '0 2px',
            color: '#49BAFD'
          }}
        >
          {page}
        </Button>
      ))}

      <Button
        disabled={currentPage === totalPages}
        onClick={handleNext}
        size="small"
        sx={{
          minWidth: '32px',
          color: '#49BAFD'
        }}
      >
        <ChevronRightIcon fontSize="small" />
      </Button>
    </Box>
  );
};

export default Pagination;
