import { RecordedAction } from '../../pages/teach/recordedAction';

const interactiveGuidanceForStep = (
  step: RecordedAction,
  performAction: boolean = false
) => {
  chrome.runtime.sendMessage({
    type: step.action_type === 'navigation' ? step.action_type : 'highlight',
    target: step.target,
    querySelector: step.querySelector,
    description: step.description,
    performAction: performAction
  });
};

export default interactiveGuidanceForStep;
