import { Box, ButtonBase, Typography } from '@mui/material';
import { SourceNode } from '../../types/message';
import DescriptionIcon from '@mui/icons-material/Description';

import SiteIcon from '../../assets/dokai-icon.svg';
import { isChromeExtApp } from '../../utils/chromeHelper';
import { useNavigate } from 'react-router-dom';
import { WEB_BASE_URL } from '../../api/constants';

interface SourcesComponentProps {
  nodes: SourceNode[];
}

const Sources = ({ nodes }: SourcesComponentProps) => {
  const navigate = useNavigate();

  const sourceIcon = (docSource: string) => {
    if (docSource === 'dokai_skill') {
      return (
        <img
          src={SiteIcon}
          alt="dokai-icon"
          style={{
            height: '24px',
            width: '24px'
          }}
        />
      );
    } else {
      return (
        <DescriptionIcon
          sx={{
            color: '#49BAFD'
          }}
        />
      );
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        marginY: '8px'
      }}
    >
      <Typography
        sx={{
          fontSize: '12px',
          fontStyle: 'italic',
          color: '#777777'
        }}
      >
        Sources
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap'
        }}
      >
        {nodes.map((node) => (
          <ButtonBase
            sx={{
              display: 'flex',
              justifyContent: 'center',
              marginRight: '5px',
              p: 1,
              ':hover': {
                backgroundColor: '#F2F2F2',
                borderRadius: '6px'
              }
            }}
            disabled={!node.fileUrl}
            onClick={() => {
              if (node.fileType === 'dokai_skill' && isChromeExtApp()) {
                const subdomain = node.fileUrl.replace(WEB_BASE_URL, '');
                navigate(subdomain);
              } else {
                window.open(node.fileUrl, '_blank');
              }
            }}
          >
            {sourceIcon(node.fileType)}
            <Box
              sx={{
                display: 'block',
                maxWidth: '100px',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                fontStyle: 'italic',
                fontSize: '12px',
                alignContent: 'center',
                marginLeft: '2px'
              }}
              title={node.fileName}
            >
              {node.fileName}
            </Box>
          </ButtonBase>
        ))}
      </Box>
    </Box>
  );
};

export default Sources;
