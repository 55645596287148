import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box
} from '@mui/material';

interface WorkflowInputModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (inputs: Record<string, string>) => void;
  requiredInputs: string[];
}

const WorkflowInputModal: React.FC<WorkflowInputModalProps> = ({
  open,
  onClose,
  onSubmit,
  requiredInputs
}) => {
  const [inputValues, setInputValues] = useState<Record<string, string>>({});

  const handleSubmit = () => {
    onSubmit(inputValues);
    onClose();
  };

  const handleInputChange = (inputName: string, value: string) => {
    setInputValues((prev) => ({
      ...prev,
      [inputName]: value
    }));
  };

  const isSubmitDisabled = () => {
    return requiredInputs.some((input) => !inputValues[input]);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Required Inputs</DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
          {requiredInputs.map((inputName) => (
            <TextField
              key={inputName}
              label={inputName}
              variant="outlined"
              fullWidth
              value={inputValues[inputName] || ''}
              onChange={(e) => handleInputChange(inputName, e.target.value)}
              required
            />
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          disabled={isSubmitDisabled()}
        >
          Start Automation
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WorkflowInputModal;
