// src/Layout.tsx
import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import Sidebar from '../SideBar/SideBar';
import { useUserContextController } from '../../context/UserContext';

// For different justify content value of window content, add URL and also the justify content value in the object below
export const URL_PATHNAMES = {
  HOME: '/home',
  GUIDE: '/guide',
  PROFILE: '/profile',
  AGENT_DASHBOARD: '/profile/agent-dashboard',
  DOCUMENTATION_LIST: '/documentation/list',
  DOCUMENTATION_DETAILS: '/documentation/details',
  TEACH: '/teach'
};

const justifyContentValue = {
  [URL_PATHNAMES.HOME]: 'start',
  [URL_PATHNAMES.GUIDE]: 'end',
  [URL_PATHNAMES.PROFILE]: 'start',
  [URL_PATHNAMES.AGENT_DASHBOARD]: 'start',
  [URL_PATHNAMES.DOCUMENTATION_LIST]: 'start',
  [URL_PATHNAMES.TEACH]: 'start'
};

interface LayoutProps {
  isWebApp: boolean;
}

const Layout = (props: LayoutProps) => {
  const [userContext] = useUserContextController();
  const location = useLocation();
  const { user } = userContext;
  const justify =
    location.pathname in justifyContentValue
      ? justifyContentValue[location.pathname]
      : 'start';

  return user ? (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: `${justify}`,
          backgroundColor: 'white',
          boxSizing: 'border-box',
          width: !location.pathname.match(URL_PATHNAMES.DOCUMENTATION_DETAILS)
            ? 'calc(100% - 50px)'
            : '100%',
          margin: `10px
            ${
              !location.pathname.match(URL_PATHNAMES.DOCUMENTATION_DETAILS)
                ? '0'
                : '10px'
            }
            10px 10px
          `,
          padding: '10px',
          borderRadius: '15px',
          overflow: location.pathname.match(URL_PATHNAMES.PROFILE)
            ? 'auto'
            : 'hidden',
          scrollbarWidth: 'none',
          '&::-webkit-scrollbar': {
            display: 'none'
          }
        }}
      >
        <Outlet />
      </Box>
      {!location.pathname.match(URL_PATHNAMES.DOCUMENTATION_DETAILS) && (
        <Sidebar
          isWebApp={props.isWebApp}
          isManager={user.isManager}
          hasAccessToAutomation={user.hasAccessToAutomation}
        />
      )}
    </>
  ) : (
    <Outlet />
  );
};

export default Layout;
