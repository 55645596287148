// DocumentIngestion.tsx
import React from 'react';
import { useUserContextController } from '../../../context/UserContext';
import GDrivePicker from '../../../components/picker/GDrivePicker';
import { ingestDocument } from '../../../api/patiaAI';
import SlackChannelPicker from '../../../components/picker/SlackChannelPicker';
import NotionPagePicker from '../../../components/picker/NotionPagePicker';
import { Box, Typography } from '@mui/material';
import { DEFAULT_AGENT_NAME } from '../../../api/constants';
import Bugsnag from '@bugsnag/js';
import { useSnackbar } from '../../../context/SnackbarContext';

const DocumentIngestion: React.FC = () => {
  const [userContext] = useUserContextController();
  const { googleCredentials, slackCredentials, notionCredentials, agentName } =
    userContext;
  const { showSnackbar } = useSnackbar();

  return (
    <Box
      sx={{
        width: '100%'
      }}
    >
      <Typography
        sx={{
          maxWidth: '100%',
          wordWrap: 'break-word',
          fontWeight: '600',
          fontSize: '26px',
          marginBottom: '20px'
        }}
      >
        Ingest Documents
      </Typography>
      <GDrivePicker
        token={googleCredentials?.access_token}
        refreshToken={googleCredentials?.refresh_token}
        onFilesSelect={(docs: any[], access_token: string | undefined) => {
          ingestDocument(
            agentName || DEFAULT_AGENT_NAME,
            docs.map((doc) => doc.id),
            'gdoc',
            access_token || '',
            docs
          )
            .then((response) => {
              showSnackbar(response.message, 'success');
            })
            .catch((error) => {
              Bugsnag.notify(error.message);
              // TODO: set BE message as required and change this error message accordingly
              showSnackbar('Failed to ingest Google document', 'error');
            });
        }}
      />
      <SlackChannelPicker
        agentName={agentName || DEFAULT_AGENT_NAME}
        slackAccessToken={slackCredentials?.access_token}
      />
      <NotionPagePicker
        agentName={agentName || DEFAULT_AGENT_NAME}
        notionAccessToken={notionCredentials?.access_token}
      />
    </Box>
  );
};

export default DocumentIngestion;
