import { BASE_URL } from './constants';

const getAccessToken = async (
  authorizationCode: string,
  redirectUri: string
) => {
  const res = await fetch(`${BASE_URL}/slack/access_token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ code: authorizationCode, redirect_uri: redirectUri })
  });

  const data = await res.json();

  return data?.authed_user?.access_token;
};

const getChannelIds = async (slackAccessToken: string) => {
  const res = await fetch(
    `${BASE_URL}/slack/channels?access_token=${slackAccessToken}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );

  return res.json();
};

export { getAccessToken, getChannelIds };
