import { BASE_URL } from './constants';

const getAccessToken = async (code: string, redirect_uri: string) => {
  const res = await fetch(
    `${BASE_URL}/notion/access_token?code=${code}&redirect_uri=${redirect_uri}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );

  return res.json();
};

const searchNotionPages = async (query: string, api_key: string) => {
  const res = await fetch(
    `${BASE_URL}/notion/search_page?query=${query}&api_key=${api_key}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );

  return res.json();
};

export { getAccessToken, searchNotionPages };
