import ChatOutput from './ChatOutput';
import ChatMarkdown from '../ChatMarkdown/ChatMarkdown';
import { Avatar, CircularProgress, Grid, Typography } from '@mui/material';
import { SourceNode } from '../../types/message';
import DokaiIcon from '../../assets/dokai-icon.svg';
import { RecordedAction } from '../../pages/teach/recordedAction';

interface ChatMessageProps {
  text: string;
  sender: string;
  isLoading?: boolean;
  showToolbox?: boolean;
  sourceNodes?: SourceNode[];
  instructions?: RecordedAction[];
}

export const ChatMessage = ({
  text,
  sender,
  isLoading = false,
  showToolbox = false,
  sourceNodes = [],
  instructions = []
}: ChatMessageProps) => {
  // replace text new line with markdown new line
  text = text.replace(/\n/g, '  \n');

  return (
    <>
      {
        // TODO: find a better way of checking if is user or AI (maybe have sender ID or something?)
        sender !== 'User' ? (
          <Grid
            container
            spacing={2}
            sx={{
              px: 0,
              boxSizing: 'border-box'
            }}
          >
            <Grid
              item
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                boxSizing: 'border-box'
              }}
            >
              <Avatar
                sx={{
                  width: 26,
                  height: 26,
                  fontSize: '0.7rem',
                  boxSizing: 'border-box',
                  img: {
                    objectFit: 'fill'
                  }
                }}
                src={DokaiIcon}
              />
            </Grid>
            <Grid
              item
              xs={10}
              sx={{
                display: 'flex',
                textAlign: 'left',
                flexDirection: 'column',
                alignItems: 'flex-start',
                overflowWrap: 'break-word',
                boxSizing: 'border-box'
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{ textTransform: 'capitalize' }}
              >
                {sender}
              </Typography>
              <ChatOutput
                isLoading={isLoading}
                text={text}
                sourceNodes={sourceNodes}
                showToolbox={showToolbox}
                stepByStep={instructions}
              />
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            spacing={2}
            sx={{
              px: 0,
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            <Grid
              item
              xs={10}
              sx={{
                padding: '16px 16px 0px 0px !important',
                display: 'flex',
                textAlign: 'left',
                flexDirection: 'column',
                alignItems: 'flex-end',
                overflowWrap: 'break-word'
              }}
            >
              <Typography variant="subtitle2">{sender}</Typography>
              {isLoading ? (
                <CircularProgress size={14} />
              ) : (
                <ChatMarkdown>{text}</ChatMarkdown>
              )}
            </Grid>
            <Grid
              item
              sx={{
                padding: '16px 16px 0px 0px !important',
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <Avatar
                sx={{
                  width: 26,
                  height: 26,
                  fontSize: '0.7rem'
                }}
              >
                {sender[0]}
              </Avatar>
            </Grid>
          </Grid>
        )
      }
    </>
  );
};
